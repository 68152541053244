(function () {
	'use strict';

	var defaults = {};
	defaults.DBK_VERSION = '{build-data::version}';

	defaults.DBK_ENVIRONMENT = getEnvironment();

	// DayBack main site urls
	defaults.DBK_SITE_URL = 'https://dayback.com';

	// DayBack API
	defaults.DBK_API_BASEURL = 'https://' + window.location.host + '/api/';
	defaults.DBK_JOURNEY_URL = defaults.DBK_API_BASEURL + 'journey';
	defaults.DBK_TRIAL_URL = defaults.DBK_API_BASEURL + 'trial';
	defaults.DBK_SUBSCRIPTION_URL = defaults.DBK_API_BASEURL + 'subscription';
	defaults.DBK_TOKENS_URL = defaults.DBK_API_BASEURL + 'tokens';
	defaults.DBK_STATUS_URL = defaults.DBK_API_BASEURL + 'webhook/status';

	// Purchase URLs
	defaults.DBK_PURCHASE_URL = 'https://dayback.com/pricing/';
	defaults.DBK_MONTHLY_COMPONENT_ID = '614718';
	defaults.DBK_YEARLY_COMPONENT_ID = '686302';
	defaults.DBK_MONTHLY_ESSENTIALS_COMPONENT_ID = '1667185';
	defaults.DBK_MONTHLY_PLUS_COMPONENT_ID = '1667183';
	defaults.DBK_YEARLY_ESSENTIALS_COMPONENT_ID = '1667184';
	defaults.DBK_YEARLY_PLUS_COMPONENT_ID = '1667182';

	// Firebase
	defaults.FIREBASE_API_URL = 'https://dayback.firebaseio.com/';
	defaults.FIREBASE_API_KEY = 'AIzaSyDKxAi28bKHwqD7BAG5QSIcdF_WNeU02Fs';
	defaults.FIREBASE_AUTH_DOMAIN = 'firebase-dayback.firebaseapp.com';
	defaults.FIREBASE_DATABASE_URL = 'https://dayback.firebaseio.com';
	defaults.FIREBASE_PROJECT_ID = 'firebase-dayback';
	// Firebase Asia
	defaults.FIREBASE_ASIA_API_URL =
		'https://dayback-asia-default-rtdb.asia-southeast1.firebasedatabase.app/';
	defaults.FIREBASE_ASIA_API_KEY = 'AIzaSyC_K8jrwLg0pVWceCjjgML-h4hRn7gUgmw';
	defaults.FIREBASE_ASIA_AUTH_DOMAIN =
		'dayback-asia.asia-southeast1.firebasedatabase.app';
	defaults.FIREBASE_ASIA_DATABASE_URL =
		'https://dayback-asia-default-rtdb.asia-southeast1.firebasedatabase.app';
	defaults.FIREBASE_ASIA_PROJECT_ID = 'dayback-asia';

	// Private API
	defaults.DBK_PRIVATE_API_URL = 'https://recover.dayback.com/firebase-data/';
	defaults.DBK_USE_STATIC_DATA = false; // Set this to true in the event that firebase realtime database is not working and we want to utilise our emergency backup data
	defaults.DBK_PREVENT_FIREBASE_AUTH = false; // Set this to true in the event that firebase auth is not working and we want to utilise our emergency backup data

	window._CONFIG = defaults;

	function getEnvironment() {
		if (
			window.location.host.includes('beta') ||
			window.location.pathname.split('/').includes('beta')
		) {
			return 'beta';
		} else if (window.location.host.includes('staging')) {
			return 'staging';
		} else if (window.location.host.includes('app')) {
			return 'production';
		} else {
			return 'development';
		}
	}
})();
